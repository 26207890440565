import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const StarIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M7.10345 3.15C7.47029 2.40684 8.53003 2.40684 8.89687 3.15L9.82772 5.0358C9.97326 5.33065 10.2544 5.5351 10.5798 5.58266L12.663 5.88716C13.483 6.007 13.8097 7.01484 13.2162 7.593L11.7103 9.05972C11.4744 9.28946 11.3667 9.62059 11.4224 9.94512L11.7776 12.016C11.9177 12.833 11.0602 13.4559 10.3265 13.0701L8.46561 12.0915C8.17423 11.9382 7.82609 11.9382 7.53471 12.0915L5.67379 13.0701C4.94016 13.4559 4.08262 12.833 4.22273 12.016L4.57792 9.94512C4.63358 9.62059 4.52591 9.28946 4.29004 9.05972L2.78417 7.593C2.19058 7.01484 2.51736 6.007 3.33728 5.88716L5.42053 5.58266C5.74588 5.5351 6.02707 5.33065 6.17261 5.0358L7.10345 3.15Z"
        fill="#F8BD38"
      />
    </SvgWrapper>
  );
};
