import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const LinkedInIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M7.37873 4.49219C7.37873 5.59244 6.47314 6.48437 5.35603 6.48437C4.23893 6.48437 3.33334 5.59244 3.33334 4.49219C3.33334 3.39193 4.23893 2.5 5.35603 2.5C6.47314 2.5 7.37873 3.39193 7.37873 4.49219Z"
        fill="#ADB5BD"
      />
      <path
        d="M3.78282 7.8125C3.78282 7.568 3.98407 7.36979 4.23231 7.36979H6.47975C6.728 7.36979 6.92924 7.568 6.92924 7.8125V16.2239C6.92924 16.4685 6.728 16.6667 6.47975 16.6667H4.23231C3.98407 16.6667 3.78282 16.4685 3.78282 16.2239V7.8125Z"
        fill="#ADB5BD"
      />
      <path
        d="M8.72719 7.36979L10.7499 7.3698C10.9981 7.3698 11.1994 7.56801 11.1994 7.81251V8.25522C12.0984 7.14844 13.7465 7.07464 14.5705 7.14843C17.1395 7.37843 17.5671 9.95225 17.4922 11.3542L17.4922 16.224C17.4922 16.4685 17.291 16.6667 17.0427 16.6667L15.02 16.6667C14.7718 16.6667 14.5705 16.4685 14.5705 16.224V11.3542C14.4956 10.7639 14.0761 9.58333 12.9973 9.58333C11.9186 9.58333 11.2743 10.9115 11.1994 11.3542V16.224C11.1994 16.4685 10.9981 16.6667 10.7499 16.6667L8.72719 16.6667C8.47894 16.6667 8.2777 16.4685 8.2777 16.224V7.8125C8.2777 7.568 8.47895 7.36979 8.72719 7.36979Z"
        fill="#ADB5BD"
      />
    </SvgWrapper>
  );
};
