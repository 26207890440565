import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const LinkArrowIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clipPath="url(#clip0_112_7410)" fill="none">
        <path d="M14.1668 6.33337L5.8335 14.6667" stroke="#868E96" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 6.33337H14.1667V13" stroke="#868E96" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_112_7410">
          <rect width="20" height="20" fill="none" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
