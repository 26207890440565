import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SlidTextLogo = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M33.0777 20.5027C37.6472 20.5027 40.3768 18.2902 40.3768 14.803C40.3768 12.0734 38.6933 10.5703 34.9897 9.86085L33.21 9.52416C31.3221 9.16341 30.5405 8.65837 30.5405 7.74449C30.5405 6.72239 31.4784 6.00091 33.0777 6.00091C34.3524 6.00091 35.3144 6.42177 35.8795 7.504C36.3966 8.34573 36.9978 8.69445 37.9598 8.69445C39.0661 8.68242 39.8116 7.99701 39.8116 6.97491C39.8116 6.61417 39.7515 6.32557 39.6313 6.02496C38.7895 3.82443 36.3605 2.5498 33.0417 2.5498C29.0494 2.5498 26.1514 4.70223 26.1514 8.04511C26.1514 10.6906 27.9552 12.386 31.4063 13.0354L33.198 13.3721C35.2542 13.7689 35.9877 14.2619 35.9877 15.2239C35.9877 16.2821 34.8694 17.0516 33.1499 17.0516C31.755 17.0516 30.5886 16.5947 29.9994 15.5125C29.4342 14.6467 28.857 14.3461 28.0033 14.3461C26.8849 14.3461 26.0913 15.0916 26.0913 16.1979C26.0913 16.5586 26.1635 16.9314 26.3198 17.2921C27.0413 19.0838 29.314 20.5027 33.0777 20.5027Z"
        fill={color ? color : "#212529"}
      />
      <path
        d="M43.8576 20.4788C45.0962 20.4788 45.986 19.7573 45.986 18.2662V4.78647C45.986 3.2954 45.0962 2.57391 43.8576 2.57391C42.607 2.57391 41.7172 3.2954 41.7172 4.78647V18.2662C41.7172 19.7573 42.607 20.4788 43.8576 20.4788Z"
        fill={color ? color : "#212529"}
      />
      <path
        d="M64.3128 20.4788C65.5634 20.4788 66.4412 19.7693 66.4412 18.2903V4.78647C66.4412 3.2954 65.5634 2.57391 64.3128 2.57391C63.0623 2.57391 62.1844 3.2954 62.1844 4.78647V9.41599H62.1003C61.5231 7.94897 60.0801 6.91485 58.1802 6.91485C54.9094 6.91485 52.9133 9.44004 52.9133 13.6247C52.9133 17.8574 54.8734 20.3946 58.1321 20.3946C60.1162 20.3946 61.5952 19.3364 62.1003 17.9175H62.1844V18.2903C62.1844 19.7693 63.0743 20.4788 64.3128 20.4788ZM59.7314 17.0637C58.1922 17.0637 57.2783 15.7771 57.2783 13.6607C57.2783 11.5564 58.2042 10.2457 59.7314 10.2457C61.2585 10.2457 62.1965 11.5564 62.1965 13.6607C62.1965 15.7891 61.2706 17.0637 59.7314 17.0637Z"
        fill={color ? color : "#212529"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3255 8.44434H49.881C50.9036 8.44434 51.7326 9.27334 51.7326 10.296V18.6283C51.7326 19.6509 50.9036 20.4799 49.881 20.4799H49.3255C48.3029 20.4799 47.4739 19.6509 47.4739 18.6283V10.296C47.4739 9.27334 48.3029 8.44434 49.3255 8.44434Z"
        fill={color ? color : "#212529"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6279 6.26698L49.5828 7.40473C48.8679 7.80247 47.9659 7.54534 47.5682 6.83043C47.4457 6.61018 47.3813 6.36231 47.3813 6.11027V3.83476C47.3813 3.01666 48.0446 2.35345 48.8627 2.35345C49.1147 2.35345 49.3626 2.41776 49.5828 2.5403L51.6279 3.67805C52.3428 4.07578 52.5999 4.97776 52.2022 5.69267C52.068 5.93388 51.8691 6.13279 51.6279 6.26698Z"
        fill={color ? color : "#212529"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9474 5.22159L48.8816 6.35773C48.7696 6.41934 48.6289 6.37848 48.5673 6.26647C48.5485 6.2323 48.5386 6.19393 48.5386 6.15493V3.88264C48.5386 3.75481 48.6423 3.65118 48.7701 3.65118C48.8091 3.65118 48.8475 3.66104 48.8816 3.67983L50.9474 4.81598C51.0594 4.87758 51.1002 5.01832 51.0386 5.13033C51.0175 5.16878 50.9858 5.20044 50.9474 5.22159Z"
        fill={color ? color : "#3182F6"}
      />
      <path
        d="M19.7715 4.45142H6.94487C6.15259 4.45142 5.51031 5.09114 5.51031 5.88028V19.4965C5.51031 20.2857 6.15259 20.9254 6.94487 20.9254H19.7715C20.5638 20.9254 21.206 20.2857 21.206 19.4965V5.88028C21.206 5.09114 20.5638 4.45142 19.7715 4.45142Z"
        fill="white"
      />
      <path
        d="M20.1091 3.737H6.43855C5.29673 3.737 4.37109 4.65895 4.37109 5.79624V19.4124C4.37109 20.5497 5.29673 21.4717 6.43855 21.4717H20.1091C21.2509 21.4717 22.1765 20.5497 22.1765 19.4124V5.79624C22.1765 4.65895 21.2509 3.737 20.1091 3.737ZM6.43855 4.99776H20.1091C20.5518 4.99776 20.9107 5.35525 20.9107 5.79624V19.4124C20.9107 19.8534 20.5518 20.2109 20.1091 20.2109H6.43855C5.9958 20.2109 5.63688 19.8534 5.63688 19.4124V5.79624C5.63688 5.35525 5.9958 4.99776 6.43855 4.99776Z"
        fill={color ? color : "#212529"}
      />
      <path
        d="M18.1681 2.35016H4.49766C3.70538 2.35016 3.06311 2.98988 3.06311 3.77902V17.3952C3.06311 18.1844 3.70538 18.8241 4.49766 18.8241H18.1681C18.9604 18.8241 19.6026 18.1844 19.6026 17.3952V3.77902C19.6026 2.98988 18.9604 2.35016 18.1681 2.35016Z"
        fill={color ? color : "#3182F6"}
      />
      <path
        d="M18.1681 1.71979H4.49762C3.3558 1.71979 2.43018 2.64174 2.43018 3.77903V17.3953C2.43018 18.5325 3.3558 19.4545 4.49762 19.4545H18.1681C19.3099 19.4545 20.2356 18.5325 20.2356 17.3953V3.77903C20.2356 2.64174 19.3099 1.71979 18.1681 1.71979ZM4.49762 2.98055H18.1681C18.6109 2.98055 18.9698 3.33804 18.9698 3.77903V17.3953C18.9698 17.8362 18.6109 18.1937 18.1681 18.1937H4.49762C4.05488 18.1937 3.69596 17.8362 3.69596 17.3953V3.77903C3.69596 3.33804 4.05488 2.98055 4.49762 2.98055Z"
        fill={color ? color : "#212529"}
      />
      <path
        d="M15.9742 4.45142H3.14751C2.35523 4.45142 1.71295 5.09114 1.71295 5.88028V19.4965C1.71295 20.2857 2.35523 20.9254 3.14751 20.9254H15.9742C16.7665 20.9254 17.4087 20.2857 17.4087 19.4965V5.88028C17.4087 5.09114 16.7665 4.45142 15.9742 4.45142Z"
        fill="white"
      />
      <path
        d="M15.9741 3.737H3.06311C1.92129 3.737 0.995667 4.65895 0.995667 5.79624V19.4124C0.995667 20.5497 1.92129 21.4717 3.06311 21.4717H15.9741C17.1159 21.4717 18.0416 20.5497 18.0416 19.4124V5.79624C18.0416 4.65895 17.1159 3.737 15.9741 3.737ZM3.06311 4.99776H15.9741C16.4169 4.99776 16.7758 5.35525 16.7758 5.79624V19.4124C16.7758 19.8534 16.4169 20.2109 15.9741 20.2109H3.06311C2.62037 20.2109 2.26145 19.8534 2.26145 19.4124V5.79624C2.26145 5.35525 2.62037 4.99776 3.06311 4.99776Z"
        fill={color ? color : "#212529"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1482 13.5487L8.40772 15.6281C7.87868 15.9222 7.21044 15.7335 6.91517 15.2065C6.82395 15.0437 6.77606 14.8604 6.77606 14.674V10.5151C6.77606 9.91169 7.26721 9.42249 7.87308 9.42249C8.06024 9.42249 8.24429 9.47018 8.40772 9.56104L12.1482 11.6405C12.6772 11.9346 12.8667 12.6002 12.5715 13.1271C12.4723 13.304 12.3258 13.4499 12.1482 13.5487Z"
        fill={color ? color : "#212529"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3627 12.7836L8.0816 14.5992C8.00012 14.6443 7.89738 14.615 7.85211 14.5339C7.83818 14.5089 7.83087 14.4808 7.83087 14.4522V10.8211C7.83087 10.7282 7.90643 10.653 7.99964 10.653C8.02832 10.653 8.05653 10.6602 8.0816 10.6741L11.3627 12.4897C11.4441 12.5348 11.4735 12.6371 11.4282 12.7183C11.4129 12.7457 11.3902 12.7683 11.3627 12.7836Z"
        fill={color ? color : "#3182F6"}
      />
    </SvgWrapper>
  );
};
