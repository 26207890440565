import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const MenuIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path d="M5.14286 6.85715H18.8571" stroke="#343A40" strokeWidth="2" strokeLinecap="round" />
      <path d="M5.14286 12H18.8571" stroke="#343A40" strokeWidth="2" strokeLinecap="round" />
      <path d="M5.14286 17.1429H18.8571" stroke="#343A40" strokeWidth="2" strokeLinecap="round" />
    </SvgWrapper>
  );
};
