import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const YouTubeIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path d="M8.16667 12.5011V7.49881L12.9333 9.99996L8.16667 12.5011Z" fill="white" />
      <path
        d="M18.7799 5.98805C18.6756 5.6357 18.4713 5.31443 18.1875 5.05641C17.9037 4.79839 17.5503 4.61266 17.1627 4.51782C15.7354 4.16663 10 4.16663 10 4.16663C10 4.16663 4.26459 4.16663 2.83729 4.51782C2.44972 4.61266 2.09634 4.79839 1.81253 5.05641C1.52872 5.31443 1.32444 5.6357 1.22011 5.98805C0.953604 7.31143 0.824274 8.65462 0.833825 9.99996C0.824274 11.3453 0.953604 12.6885 1.22011 14.0119C1.32444 14.3642 1.52872 14.6855 1.81253 14.9435C2.09634 15.2015 2.44972 15.3873 2.83729 15.4821C4.26459 15.8333 10 15.8333 10 15.8333C10 15.8333 15.7354 15.8333 17.1627 15.4821C17.5503 15.3873 17.9037 15.2015 18.1875 14.9435C18.4713 14.6855 18.6756 14.3642 18.7799 14.0119C19.0464 12.6885 19.1757 11.3453 19.1662 9.99996C19.1757 8.65462 19.0464 7.31143 18.7799 5.98805ZM8.16676 12.5V7.49996L12.9266 9.99996L8.16676 12.5Z"
        fill="#ADB5BD"
      />
    </SvgWrapper>
  );
};
