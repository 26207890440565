import axios from "axios";
import env from "@/config/env";

axios.defaults.withCredentials = true;
const SLID_SERVER_URL = env.end_point_url.slid_api;

/* deciding api endpoints */
const user_exists_endpoint = `${SLID_SERVER_URL}/v8/user-exists/`;

export const ServerInterface_getShareDocument = async ({ documentKey, serverErrorHandler }) => {
  let documentData = null;
  try {
    const documentGetResponse = await axios.get(`${SLID_SERVER_URL}/v8/share/${documentKey}/`);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "DOCUMENT_NOT_PUBLIC";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_PUBLIC";
          break;
        case 404:
          if (error.response.data.message === "Document Not Public") {
            error.custom_message = "DOCUMENT_NOT_PUBLIC";
            error.additionalMessage = {
              message: "there are no document for given id",
            };
            documentData.error_message = "DOCUMENT_NOT_PUBLIC";
          } else {
            error.custom_message = "DOCUMENT_NOT_FOUND";
            error.additionalMessage = {
              message: "there are no document for given id",
            };
            documentData.error_message = "DOCUMENT_NOT_FOUND";
          }
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_SHARE_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting share document from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_isSlidUser = async ({ cognitoIdToken }) => {
  const config = {
    headers: {
      Authorization: `${cognitoIdToken}`,
    },
  };
  try {
    const response = await axios.get(user_exists_endpoint, config);
    const responseMessage = response.data.message;
    if (responseMessage === "User Found") {
      return true;
    }
    return false; // "User Not Found"
  } catch (error) {
    return false;
  }
};
