import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { trackEvent } from "src/utils/eventTracking";
import { deviceType } from "react-device-detect";
import version from "version.json";
import { SlidTextLogo } from "../icons/SlidTextLogo";
import { Typography15, Typography11, Typography13 } from "@slid/slid-ips";
import SocialMediaIconContainer from "../containers/SocialMediaIconContainer";
import LanguageMenu from "../menus/LanguageMenu";

import env from "@/config/env";
import { useAppSelector } from "@/hooks";

interface LandingFooterProps {
  executeScrollToFeature: () => void;
  executeScrollToDownload: () => void;
  isDesktop: boolean;
}

const LandingFooter = ({ executeScrollToFeature, executeScrollToDownload, isDesktop }: LandingFooterProps) => {
  const router = useRouter();
  const { t } = useTranslation(["Landing", "Footer"]);
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

  const LINK_LIST = [
    {
      group: t("Product", { ns: "Landing" }),
      list: [
        {
          label: t("Feature", { ns: "Landing" }),
          callback: () => {
            executeScrollToFeature();
            trackEvent({
              eventType: "Click FEATURE button",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("Pricing"),
          callback: () => {
            trackEvent({
              eventType: "Click PRICING button",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
              },
            });
            window.open(`${SLID_WEB_APP_URL}/pricing`);
          },
        },
      ],
    },
    {
      group: t("Download", { ns: "Landing" }),
      list: [
        {
          label: t("WebExtension", { ns: "Landing" }),
          callback: () => {
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("DesktopApp", { ns: "Landing" }),
          callback: () => {
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
              },
            });
          },
        },
        {
          label: t("MobileApp", { ns: "Landing" }),
          callback: () => {
            executeScrollToDownload();
            trackEvent({
              eventType: "Click SCROLL TO DOWNLOAD button",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
              },
            });
          },
        },
      ],
    },
    {
      group: t("Support", { ns: "Landing" }),
      list: [
        {
          label: t("Updates", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "Updates",
              },
            });
            window.open(t("UpdatesLink", { ns: "Landing" }));
          },
        },
        {
          label: t("FAQs", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "FAQs",
              },
            });
            window.open(t("FAQsLink", { ns: "Landing" }));
          },
        },
        {
          label: t("Contact", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "Contact",
              },
            });
            window.open(t("ContactLink", { ns: "Landing" }));
          },
        },
      ],
    },
    {
      group: t("Company", { ns: "Landing" }),
      list: [
        {
          label: t("About", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "About",
              },
            });
            window.open(t("AboutLink", { ns: "Landing" }));
          },
        },
        {
          label: t("PrivacyPolicy", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "Privacy Policy",
              },
            });
            window.open(t("PrivacyPolicyLink", { ns: "Landing" }));
          },
        },
        {
          label: t("TermsOfService", { ns: "Landing" }),
          callback: () => {
            trackEvent({
              eventType: "Click link in footer",
              eventProperties: {
                origin: "footer",
                deviceType: deviceType,
                label: "Terms Of Service",
              },
            });
            window.open(t("TermsOfServiceLink", { ns: "Landing" }));
          },
        },
      ],
    },
  ];

  const getLinks = () => {
    return LINK_LIST.map((item, index) => {
      // if the item is for "company" links, then return
      if (isDesktop && index === 3) return;
      return (
        <LinkGroupWrapper key={index}>
          <Typography15 text={item.group} color={`--gray13`} weight={700} />
          <LinkItemWarpper>
            {item.list.map((item, index) => {
              return <LinkText text={item.label} color={`--gray9`} marginTop={`12px`} onClick={item.callback} key={index} />;
            })}
          </LinkItemWarpper>
        </LinkGroupWrapper>
      );
    });
  };

  const getFooterBottomLinks = () => {
    const arr: {
      label: string;
      callback: () => void;
    }[] = [];

    LINK_LIST.forEach((item, index) => {
      if (index === 3) {
        // on desktop, add "company" links to the bottom of the footer
        item.list.map((item) => {
          arr.push(item);
        });
      }
    });

    return arr.map((item, index) => {
      return <LinkText text={item.label} color={`--gray9`} key={index} onClick={item.callback} />;
    });
  };

  return (
    <>
      {isDesktop ? (
        <FooterContainer>
          <FooterBodyContainer>
            <SlidInfoWrapper>
              <LogoWrapper onClick={() => router.push("/")} isDesktop={isDesktop}>
                <SlidTextLogo color={`var(--gray9)`} width={80} height={28} />
              </LogoWrapper>
              {lang !== "ko" ? (
                <a href="https://www.producthunt.com/products/slid?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-slid" target="_blank" rel="noreferrer">
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?post_id=452530&theme=neutral"
                    alt="Slid - Capture&#0032;knowledge&#0032;from&#0032;online&#0032;videos&#0044;&#0032;courses&#0044;&#0032;and&#0032;webinars | Product Hunt"
                    style={{ width: "250px", height: "54px" }}
                    width="250"
                    height="54"
                  />
                </a>
              ) : (
                <CompanyInfo>
                  <Typography13 text={t("businessNumber", { ns: "Footer" })} color={`--gray7`} />
                  <Typography13 text={t("registration", { ns: "Footer" })} color={`--gray7`} />
                  <Typography13 text={t("address", { ns: "Footer" })} color={`--gray7`} />
                  <br />
                  <Typography13 text={t("tel", { ns: "Footer" })} color={`--gray7`} />
                  <Typography13 text={t("email", { ns: "Footer" })} color={`--gray7`} />
                </CompanyInfo>
              )}
            </SlidInfoWrapper>
            <SlidLinkGroupWrapper>{getLinks()}</SlidLinkGroupWrapper>
          </FooterBodyContainer>
          <Dvider />
          <FooterBottomContainer>
            <FooterBottomContentWrapper>
              <LanguageMenu color={`--gray9`} />
              <BottomLinkWrapper>{getFooterBottomLinks()}</BottomLinkWrapper>
              <Typography15 text={t("copyRight", { ns: "Footer" })} color={`--gray7`} />
            </FooterBottomContentWrapper>
            <SocialMediaIconContainer />
          </FooterBottomContainer>
          <VersionContainer>
            <Typography11 text={`v${version["version"]}`} color={`--gray7`} />
          </VersionContainer>
        </FooterContainer>
      ) : (
        <MobileFooterWrapper>
          {getLinks()}
          <FooterBottomContainer>
            <MobileFooterBottomContentWrapper>
              <SocialMediaIconContainer />
              <Typography11 text={t("copyRight", { ns: "Footer" })} color={`--gray7`} style={{ display: "inline-block" }} />
            </MobileFooterBottomContentWrapper>
            <LanguageMenu color={`--gray9`} />
          </FooterBottomContainer>
        </MobileFooterWrapper>
      )}
    </>
  );
};

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 57px 144px 120px 144px;
  max-width: 1552px;
`;

const VersionContainer = styled.div`
  padding: 10px;
`;

const FooterBodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SlidInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CompanyInfo = styled.div``;

const LogoWrapper = styled.div<{ isDesktop: boolean }>`
  cursor: pointer;
`;

const SlidLinkGroupWrapper = styled.div`
  display: flex;
  gap: 96px;
`;

const LinkGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkItemWarpper = styled.div`
  margin-top: 8px;
`;

const LinkText = styled(Typography15)`
  cursor: pointer;
`;

const Dvider = styled.div`
  height: 0;
  width: 100%;
  border-top: 1px solid var(--gray3);
  margin-top: 72px;
  margin-bottom: 16px;
`;

const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 1224px) {
    align-items: flex-start;
  }
`;

const FooterBottomContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLinkWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin: 0 56px;
`;

const MobileFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 375px;
  width: 100%;
  padding: 0 20px 180px 20px;
`;

const MobileFooterBottomContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 8px 0;
`;
export default LandingFooter;
