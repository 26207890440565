import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toggleSharedNoteLoadingScreen } from "@/redux/modules/sharedNotesSlice";
import { useIntersectionObserver } from "usehooks-ts";
import { useAppDispatch } from "src/hooks";

export const useCallFirstTrackEvent = (func: Function) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, [didMount.current]);
};

export const useIsMobile = () => {
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: "(max-width:799px)" });
  useEffect(() => {
    setIsMobile(mobile);
    dispatch(toggleSharedNoteLoadingScreen(false));
  }, [mobile, dispatch]);

  return isMobile;
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    windowWidth: number;
    windowHeight: number;
  }>({
    windowWidth: 0,
    windowHeight: 0,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      const handleResize = () => {
        // Set window windowWidth/windowHeight to state
        setWindowSize({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

interface PageVisbleDurationHook {
  (): { duration: number };
}

export const useIsPageVisibleDuration: PageVisbleDurationHook = () => {
  interface TabActiveDuration {
    lastViewTime: number;
    totalViewTime: number;
    isPaused: boolean;
  }

  const tabActiveDuration = useRef<TabActiveDuration>({ lastViewTime: 0, totalViewTime: 0, isPaused: false });

  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let updateTimerIntervalId: NodeJS.Timer | string = "";

    tabActiveDuration.current.lastViewTime = performance.now();

    const updateDuration = () => {
      const currentTime = performance.now();

      const lastViewTime = tabActiveDuration.current.lastViewTime;

      const diff = currentTime - lastViewTime;

      tabActiveDuration.current.totalViewTime += diff;

      tabActiveDuration.current.lastViewTime = currentTime;

      setDuration(tabActiveDuration.current.totalViewTime);
    };

    const handleVisibilityChange = (event: Event) => {
      if (document.visibilityState == "visible") {
        if (tabActiveDuration.current.isPaused) {
          tabActiveDuration.current.isPaused = false;
          tabActiveDuration.current.lastViewTime = performance.now();
          updateTimerIntervalId = setInterval(updateDuration, 1000);
        }
      } else {
        tabActiveDuration.current.isPaused = true;
        clearInterval(updateTimerIntervalId);
      }
    };

    updateTimerIntervalId = setInterval(updateDuration, 1000);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(updateTimerIntervalId);
    };
  }, []);

  return { duration };
};

interface ElementAppearsOnScreenHook {
  (ref: React.MutableRefObject<HTMLElement | null>, triggerOnlyOnFirstAppearance?: boolean): boolean;
}

export const useElementApppearsOnScreen: ElementAppearsOnScreenHook = (ref, triggerOnlyOnFirstAppearance = false) => {
  /**
   * use to track if given dom element has appeared on screen
   */
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: triggerOnlyOnFirstAppearance });

  return !!entry?.isIntersecting;
};
