import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SlidTextSmallLogo = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M29.6218 17.9399C33.7139 17.9399 36.1583 16.0039 36.1583 12.9527C36.1583 10.5642 34.6507 9.24903 31.334 8.62826L29.7403 8.33365C28.0496 8.018 27.3497 7.57609 27.3497 6.77645C27.3497 5.88211 28.1896 5.25081 29.6218 5.25081C30.7633 5.25081 31.6248 5.61907 32.1309 6.56601C32.5939 7.30253 33.1324 7.60766 33.9938 7.60766C34.9845 7.59714 35.6522 6.9974 35.6522 6.10306C35.6522 5.78741 35.5984 5.53489 35.4907 5.27185C34.7369 3.34639 32.5616 2.23109 29.5895 2.23109C26.0144 2.23109 23.4192 4.11447 23.4192 7.03949C23.4192 9.35425 25.0344 10.8378 28.125 11.406L29.7295 11.7006C31.5709 12.0478 32.2278 12.4792 32.2278 13.3209C32.2278 14.2468 31.2263 14.9202 29.6864 14.9202C28.4373 14.9202 27.3927 14.5204 26.8651 13.5734C26.359 12.8159 25.8421 12.5528 25.0775 12.5528C24.076 12.5528 23.3653 13.2052 23.3653 14.1732C23.3653 14.4888 23.4299 14.815 23.5699 15.1306C24.216 16.6984 26.2513 17.9399 29.6218 17.9399Z"
        fill="#2B2B2B"
      />
      <path
        d="M39.2754 17.9189C40.3846 17.9189 41.1815 17.2876 41.1815 15.9829V4.18815C41.1815 2.88347 40.3846 2.25217 39.2754 2.25217C38.1555 2.25217 37.3586 2.88347 37.3586 4.18815V15.9829C37.3586 17.2876 38.1555 17.9189 39.2754 17.9189Z"
        fill="#2B2B2B"
      />
      <path
        d="M57.5935 17.9189C58.7135 17.9189 59.4996 17.2981 59.4996 16.004V4.18815C59.4996 2.88347 58.7135 2.25217 57.5935 2.25217C56.4736 2.25217 55.6875 2.88347 55.6875 4.18815V8.23899H55.6121C55.0953 6.95534 53.803 6.05048 52.1016 6.05048C49.1726 6.05048 47.385 8.26003 47.385 11.9216C47.385 15.6252 49.1403 17.8453 52.0586 17.8453C53.8354 17.8453 55.1599 16.9193 55.6121 15.6778H55.6875V16.004C55.6875 17.2981 56.4844 17.9189 57.5935 17.9189ZM53.4908 14.9308C52.1124 14.9308 51.294 13.8049 51.294 11.9531C51.294 10.1118 52.1232 8.96498 53.4908 8.96498C54.8584 8.96498 55.6983 10.1118 55.6983 11.9531C55.6983 13.8155 54.8691 14.9308 53.4908 14.9308Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1721 7.38876H44.6696C45.5853 7.38876 46.3277 8.11414 46.3277 9.00894V16.2997C46.3277 17.1945 45.5853 17.9199 44.6696 17.9199H44.1721C43.2563 17.9199 42.5139 17.1945 42.5139 16.2997V9.00894C42.5139 8.11414 43.2563 7.38876 44.1721 7.38876Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2339 5.4836L44.4025 6.47913C43.7623 6.82715 42.9545 6.60216 42.5984 5.97662C42.4886 5.7839 42.431 5.56701 42.431 5.34648V3.35541C42.431 2.63957 43.0249 2.05927 43.7576 2.05927C43.9833 2.05927 44.2053 2.11554 44.4025 2.22275L46.2339 3.21828C46.8741 3.5663 47.1044 4.35553 46.7482 4.98108C46.628 5.19213 46.4499 5.36618 46.2339 5.4836Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.6245 4.5689L43.7745 5.56302C43.6742 5.61693 43.5482 5.58118 43.493 5.48317C43.4762 5.45327 43.4674 5.4197 43.4674 5.38557V3.39732C43.4674 3.28547 43.5602 3.19479 43.6747 3.19479C43.7096 3.19479 43.7439 3.20342 43.7745 3.21986L45.6245 4.21399C45.7248 4.26789 45.7613 4.39104 45.7062 4.48904C45.6872 4.52269 45.6589 4.55039 45.6245 4.5689Z"
        fill="#2774FF"
      />
      <path
        d="M17.7058 3.89505H6.21931C5.5098 3.89505 4.93463 4.45481 4.93463 5.14531V17.0595C4.93463 17.75 5.5098 18.3098 6.21931 18.3098H17.7058C18.4153 18.3098 18.9905 17.75 18.9905 17.0595V5.14531C18.9905 4.45481 18.4153 3.89505 17.7058 3.89505Z"
        fill="white"
      />
      <path
        d="M18.0081 3.26991H5.76588C4.74335 3.26991 3.91443 4.07662 3.91443 5.07175V16.9859C3.91443 17.9811 4.74335 18.7878 5.76588 18.7878H18.0081C19.0307 18.7878 19.8596 17.9811 19.8596 16.9859V5.07175C19.8596 4.07662 19.0307 3.26991 18.0081 3.26991ZM5.76588 4.37308H18.0081C18.4046 4.37308 18.7261 4.68588 18.7261 5.07175V16.9859C18.7261 17.3718 18.4046 17.6846 18.0081 17.6846H5.76588C5.36939 17.6846 5.04797 17.3718 5.04797 16.9859V5.07175C5.04797 4.68588 5.36939 4.37308 5.76588 4.37308Z"
        fill="#282828"
      />
      <path
        d="M16.27 2.05643H4.02778C3.31827 2.05643 2.7431 2.61618 2.7431 3.30668V15.2209C2.7431 15.9114 3.31827 16.4711 4.02778 16.4711H16.27C16.9795 16.4711 17.5546 15.9114 17.5546 15.2209V3.30668C17.5546 2.61618 16.9795 2.05643 16.27 2.05643Z"
        fill="#017BFF"
      />
      <path
        d="M16.27 1.50484H4.02778C3.00525 1.50484 2.17633 2.31155 2.17633 3.30668V15.2209C2.17633 16.216 3.00525 17.0227 4.02778 17.0227H16.27C17.2925 17.0227 18.1214 16.216 18.1214 15.2209V3.30668C18.1214 2.31155 17.2925 1.50484 16.27 1.50484ZM4.02778 2.608H16.27C16.6665 2.608 16.9879 2.92081 16.9879 3.30668V15.2209C16.9879 15.6067 16.6665 15.9195 16.27 15.9195H4.02778C3.63129 15.9195 3.30987 15.6067 3.30987 15.2209V3.30668C3.30987 2.92081 3.63129 2.608 4.02778 2.608Z"
        fill="#282828"
      />
      <path
        d="M14.3053 3.89505H2.81871C2.1092 3.89505 1.53403 4.45481 1.53403 5.14531V17.0595C1.53403 17.75 2.1092 18.3098 2.81871 18.3098H14.3053C15.0148 18.3098 15.59 17.75 15.59 17.0595V5.14531C15.59 4.45481 15.0148 3.89505 14.3053 3.89505Z"
        fill="white"
      />
      <path
        d="M14.3052 3.26991H2.74311C1.72058 3.26991 0.891663 4.07662 0.891663 5.07175V16.9859C0.891663 17.9811 1.72058 18.7878 2.74311 18.7878H14.3052C15.3277 18.7878 16.1566 17.9811 16.1566 16.9859V5.07175C16.1566 4.07662 15.3277 3.26991 14.3052 3.26991ZM2.74311 4.37308H14.3052C14.7017 4.37308 15.0231 4.68588 15.0231 5.07175V16.9859C15.0231 17.3718 14.7017 17.6846 14.3052 17.6846H2.74311C2.34662 17.6846 2.0252 17.3718 2.0252 16.9859V5.07175C2.0252 4.68588 2.34662 4.37308 2.74311 4.37308Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.879 11.8551L7.52933 13.6746C7.05557 13.932 6.45714 13.7668 6.19272 13.3058C6.11103 13.1633 6.06815 13.0029 6.06815 12.8398V9.2008C6.06815 8.67277 6.50798 8.24472 7.05055 8.24472C7.21816 8.24472 7.38298 8.28645 7.52933 8.36595L10.879 10.1855C11.3528 10.4428 11.5225 11.0252 11.2581 11.4863C11.1693 11.641 11.0381 11.7688 10.879 11.8551Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1755 11.1857L7.23729 12.7743C7.16433 12.8138 7.07231 12.7882 7.03178 12.7172C7.0193 12.6953 7.01276 12.6707 7.01276 12.6457V9.46845C7.01276 9.38722 7.08042 9.32137 7.16389 9.32137C7.18958 9.32137 7.21484 9.32774 7.23729 9.33988L10.1755 10.9285C10.2485 10.968 10.2748 11.0575 10.2343 11.1285C10.2206 11.1525 10.2002 11.1723 10.1755 11.1857Z"
        fill="#007BFF"
      />
    </SvgWrapper>
  );
};
