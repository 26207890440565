import React from "react";
import styled from "@emotion/styled";
import { YouTubeIcon } from "../icons/YouTubeIcon";
import { TelegramIcon } from "../icons/TelegramIcon";
import { LinkedInIcon } from "../icons/LinkedInIcon";
import { TwitterIcon } from "../icons/TwitterIcon";

const SocialMediaIconContainer = () => {
  return (
    <SocialMediaContainer>
      <SocialMediaIconWrapper onClick={() => window.open("https://bit.ly/slid-youtube2022")}>
        <YouTubeIcon width={20} height={20} />
      </SocialMediaIconWrapper>
      <SocialMediaIconWrapper onClick={() => window.open("https://t.me/slidglobal")}>
        <TelegramIcon width={20} height={20} />
      </SocialMediaIconWrapper>
      <SocialMediaIconWrapper onClick={() => window.open("https://bit.ly/slid-linkedin")}>
        <LinkedInIcon width={20} height={20} />
      </SocialMediaIconWrapper>
      <SocialMediaIconWrapper onClick={() => window.open("https://twitter.com/slid_team")}>
        <TwitterIcon width={20} height={20} />
      </SocialMediaIconWrapper>
    </SocialMediaContainer>
  );
};

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const SocialMediaIconWrapper = styled.div`
  cursor: pointer;
`;

export default SocialMediaIconContainer;
