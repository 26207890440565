import React from "react";
import { Typography15 } from "@slid/slid-ips";
import { useTranslation } from "next-i18next";
import useMeasure from "react-use-measure";
import styled from "@emotion/styled";

interface ToggleProps {
  onToggle: (type: string) => void;
  downloadType: string;
}

const DownloadToggleButton = ({ onToggle, downloadType }: ToggleProps) => {
  const { t } = useTranslation("Landing");
  const [webButtonRef, webButtonRefBounds] = useMeasure();
  const [desktopButtonRef, desktopButtonRefBounds] = useMeasure();
  const [mobileButtonRef, mobileButtonRefBounds] = useMeasure();

  const bounds = {
    WebExtension: webButtonRefBounds,
    DesktopApp: desktopButtonRefBounds,
    MobileApp: mobileButtonRefBounds,
  };

  return (
    <>
      <ToggleButtonWrapper>
        <ToggleContainer downloadType={downloadType} bounds={bounds} />
        <ToggleText
          onClick={() => {
            onToggle("MobileApp");
          }}
          downloadType={downloadType}
          ref={mobileButtonRef}
        >
          <Typography15 text={t("Mobile")} color={downloadType === "MobileApp" ? "--gray13" : "--gray7"} weight={700} />
        </ToggleText>
        <ToggleText
          onClick={() => {
            onToggle("WebExtension");
          }}
          downloadType={downloadType}
          ref={webButtonRef}
        >
          <Typography15 text={t("Web")} color={downloadType === "WebExtension" ? "--gray13" : "--gray7"} weight={700} />
        </ToggleText>
        <ToggleText
          onClick={() => {
            onToggle("DesktopApp");
          }}
          downloadType={downloadType}
          ref={desktopButtonRef}
        >
          <Typography15 text={t("Desktop")} color={downloadType === "DesktopApp" ? "--gray13" : "--gray7"} weight={700} />
        </ToggleText>
      </ToggleButtonWrapper>
    </>
  );
};

const ToggleButtonWrapper = styled.div`
  user-select: none;
  width: 100%;
  height: 47px;
  margin-top: 80px;
  border-radius: 8px;
  background: var(--gray3);
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px;
`;

const ToggleContainer = styled.div<{ downloadType: string; bounds: any }>`
  height: 39px;
  background-color: var(--gray1);
  border-radius: 8px;
  box-shadow: var(--boxShadow1);
  position: absolute;
  display: flex;
  align-items: center;
  width: ${({ downloadType, bounds }) => `${bounds[downloadType]?.width}px`};

  /* Change position */
  transform: ${({ downloadType, bounds }) =>
    downloadType === "MobileApp"
      ? "0"
      : downloadType === "WebExtension"
      ? `translateX(${bounds["MobileApp"]?.width}px)`
      : `translateX(${bounds["WebExtension"]?.width + bounds["MobileApp"]?.width}px)`};
  transition: transform 0.4s cubic-bezier(0.43, 0.13, 0.23, 0.96);
`;

const ToggleText = styled.button<{ downloadType: string }>`
  height: 39px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  flex: 1;
  text-align: center;
  z-index: 2;

  p {
    transition: color 0.4s cubic-bezier(0.43, 0.13, 0.23, 0.96);
    width: 100%;
  }
`;

export default DownloadToggleButton;
