import React from "react";
import styled from "@emotion/styled";
import Icon from "./icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const TwitterIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clipPath="url(#clip0_321_5874)">
        <g clipPath="url(#clip1_321_5874)">
          <path
            d="M18.3333 3.42531C18.3333 3.42534 18.3333 3.42538 18.3333 3.42539C17.5399 3.8134 16.7616 3.98203 15.9553 4.21336C15.8808 4.23473 15.8009 4.21134 15.747 4.15568C14.8151 3.19361 13.4738 3.15352 12.1833 3.6358C10.8525 4.13318 9.98084 5.3492 10 6.74404V7.38083C10 7.4893 9.91337 7.57826 9.8049 7.57853C7.28606 7.58473 5.0385 6.45982 3.52082 4.5003C3.43129 4.38471 3.25505 4.39581 3.19315 4.52827C2.62605 5.74195 0.787276 10.6124 6.32708 13.2436C6.4702 13.3116 6.49243 13.5134 6.35867 13.5983C5.14268 14.3702 3.91594 14.9198 2.52124 15.0414C2.31054 15.0598 2.23809 15.3583 2.42944 15.4484C4.96997 16.645 7.66559 17.0079 10.0283 16.3233C13.0117 15.4583 15.4633 13.2267 16.4042 9.88389C16.6848 8.86722 16.8242 7.81688 16.8183 6.76234C16.8167 6.55528 18.0761 4.45771 18.3332 3.42526C18.3332 3.42515 18.3333 3.42519 18.3333 3.42531Z"
            fill="#ADB5BD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_321_5874">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_321_5874">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
